html,body {
  /* mobile viewport bug fix */
  max-height: -webkit-fill-available;
}

html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

div[data-ui-id="stream-media-container"] > div > video {
    object-fit: contain!important;
}

span[data-ui-id="video-tile-display-name"] {
    display: none!important;
}